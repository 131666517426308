import router from './router'
import store from './store'
import { getToken } from '@/utils/auth'

// const whiteList = []
store.dispatch('GET_TOKEN')

router.beforeEach((to, from, next) => {

  if (getToken()) {
    /* has token*/
    if (store.getters.token) {
      next()
    } else {
      return
    }
  } else {
    next('http://labweb.terabits.cn/login') 
  }
 })

// router.afterEach(() => {
//   // NProgress.done()
// })
