import { getToken, setToken,removeToken } from '@/utils/auth'
// import { infantLogin } from '@/api'
// import request from '../../utils/request'

function getQueryVariable (variable) {
  const arr = window.location.search.split("?")
  if (arr.length <= 1) return

  var vars = arr[1].split("&");
  // console.log(vars)
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

const user = {
  state: {
    token: getToken(),
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
 
  },

  actions: {
    GET_TOKEN ({ commit }) {
      const token = getQueryVariable('token')
      commit('SET_TOKEN', token)
      setToken(token)
     
    },
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
