<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
}
</style>
